import {
  OptimoRoute,
  PdfProductionDayData,
  PdfProductionGroup,
  PdfProductionPlace,
  PdfProductionPlaceDeliveryComponent,
  PdfProductionPlaceTour,
  TourGroupsDeliveries,
  TourTableMap,
  TourTableObjectInput,
} from '../types'
import HTMLObject from './htmlObject'
import { Group } from '../model/group'
import { MealComponent } from '../model/mealComponent'
import { Tour } from '../model/tour'
import { GroupDeliveryComponent } from '../model/groupDeliveryComponent'
import { GroupDelivery } from '../model/groupDelivery'
import { ProductionPlace } from '../model/productionPlaces'
import { KitchenEmployeeAssignment } from '../model/employeeKitchenAssignment'
import { Employee } from '../model/employee'
import { ProductionPlaceAssignments } from '../model/productionPlaceAssignments'
import { getEmployeesForProductionPlace } from '../index'
import { DateTime } from 'luxon'
import { getStatisticsFunctionsBasedOnGlobalSettings } from '../../../../services/firestore/global-settings.service'

const activeUrl =
  'https://firebasestorage.googleapis.com/v0/b/kinderkueche-development.appspot.com/o/icons%2FiconsBoxTypeActiveplate%402x.png?alt=media&token=43176461-506c-478b-88cd-cd24f0f0f34d'
const passiveUrl =
  'https://firebasestorage.googleapis.com/v0/b/kinderkueche-development.appspot.com/o/icons%2FiconsBoxTypePassiveplate%402x.png?alt=media&token=b45927e4-6339-43ca-af73-1c492d864975'
const thermoUrl =
  'https://firebasestorage.googleapis.com/v0/b/kinderkueche-development.appspot.com/o/icons%2FiconsBoxTypeThermoport%402x.png?alt=media&token=ad98d0c7-8c64-4c42-a74c-f7662ccef2f5'

var HeatingType
;(function(HeatingType) {
  HeatingType[(HeatingType['Passive'] = 1)] = 'Passive'
  HeatingType[(HeatingType['Active1of2'] = 2)] = 'Active1of2'
  HeatingType[(HeatingType['Active2of2'] = 3)] = 'Active2of2'
  HeatingType[(HeatingType['Thermo'] = 4)] = 'Thermo'
})((HeatingType = HeatingType || (HeatingType = {})))

export async function createProductionPlanPdf(
  data: PdfProductionDayData
): Promise<string> {
  const htmlBody = await generateHTMLTable(data)
  let pickerIndex = 0
  for (const tourTableMap of data.pickerTourTableMap) {
    pickerIndex++
    let tourIndex = 0
    for (const tour of tourTableMap) {
      tourIndex++
      const [title, tourDetails, table] = await generateTourTable(
        tour,
        tourIndex,
        tourTableMap.length,
        pickerIndex
      )
      htmlBody.insertChild(title)
      htmlBody.insertChild(tourDetails)
      htmlBody.insertChild(table)
    }
  }

  if (data.routes != null) {
    let tourIndex = 0
    const sortedRoutes = [...data.routes]
    const sortedRoutesWithStartDate = sortedRoutes.map(route => {
      const startTime = route.stops.find(a => a.stopNumber === 1).scheduledAtDt
      return { ...route, startTime }
    })
    sortedRoutesWithStartDate.sort((a, b) => {
      const startA = DateTime.fromFormatExplain(
        a.startTime,
        'yyyy-MM-dd HH:mm:ss'
      )
      const startB = DateTime.fromFormatExplain(
        b.startTime,
        'yyyy-MM-dd HH:mm:ss'
      )
      const dateA = new Date(
        startA.result.year,
        startA.result.month,
        startA.result.day,
        startA.result.hour,
        startA.result.minute
      )
      const dateB = new Date(
        startB.result.year,
        startB.result.month,
        startB.result.day,
        startB.result.hour,
        startB.result.minute
      )
      return dateA.getTime() < dateB.getTime() ? -1 : 1
    })
    for (const route of sortedRoutesWithStartDate) {
      tourIndex++
      const htmlRouteTable = generateRouteTable(route, tourIndex)
      htmlBody.insertChild(htmlRouteTable)
    }
  }
  return htmlBody.render()
}

const roundWeight = weight => {
  const doubleWeight = weight * 2
  const roundedDoubleWeight = Math.round(doubleWeight)
  return roundedDoubleWeight / 2
}

const getWeightString = (weight, unit) => {
  // toLocaleString() mit "de-DE" als Parameter hat trotzdem , statt . als Trennzeichen. Deshalb das .replace()
  // Punkte müssen mit Kommas getauscht werden.
  const roundedWeight = roundWeight(weight)
    .toLocaleString()
    .replace('.', ':')
    .replace(',', '.')
    .replace(':', ',')
  return roundedWeight + ' ' + unit
}

const generateRouteTable: (
  route: OptimoRoute,
  tourIndex: number
) => HTMLObject = (route, tourIndex) => {
  const headerDiv = new HTMLObject(
    'div',
    'font-size: 20px;margin-top: 8px;margin-bottom: 16px;',
    `Tour ${tourIndex} - ${route.driverName}`
  )
  const header = new HTMLObject('th', null, [headerDiv])
  const tr = new HTMLObject('tr', null, [header])
  const thead = new HTMLObject('thead', 'padding-top: 80px;', [tr])

  const stopNumber = new HTMLObject(
    'td',
    'display:flex;align-items:center; width: 5%; font-weight:bold;font-size: 12px; text-align: left; float: left;',
    'No.'
  )
  const arrivalTime = new HTMLObject(
    'td',
    'display:flex;align-items:center; width: 10%; font-weight:bold;font-size: 12px; text-align: left; float: left;',
    'Zeit'
  )
  const name = new HTMLObject(
    'td',
    'display:flex;align-items:center; width: 30%; font-weight:bold;font-size: 12px; text-align: left; float: left;',
    'Name'
  )
  const address = new HTMLObject(
    'td',
    'display:flex;align-items:center; width: 40%; font-weight:bold; font-size: 12px; text-align: left; float: left;',
    'Adresse'
  )
  const headlineRow = new HTMLObject('tr', 'border-bottom: 1px solid black;', [
    stopNumber,
    arrivalTime,
    name,
    address,
  ])
  const stopsBody = new HTMLObject('tbody', 'margin-top: 16px; ', [headlineRow])

  let rowIndex = 0
  for (const stop of route.stops) {
    const stopNumber = new HTMLObject(
      'td',
      'display:flex;align-items:center; height: 40px; width: 5%; font-size: 12px; text-align: left; float: left;',
      stop.stopNumber.toString()
    )
    const arrivalTime = new HTMLObject(
      'td',
      'display:flex;align-items:center; height: 40px; width: 10%; font-size: 12px; text-align: left; float: left;',
      stop.scheduledAt.toString()
    )
    const name = new HTMLObject(
      'td',
      'display:flex;align-items:center; height: 40px; width: 30%; font-size: 12px; text-align: left; float: left;',
      stop.locationName.toString()
    )
    const address = new HTMLObject(
      'td',
      'display:flex;align-items:center; height: 40px; width: 40%; font-size: 12px; text-align: left; float: left;',
      stop.address.toString()
    )

    const backgroundStyle =
      rowIndex % 2 === 0
        ? 'background-color: #f8f8f8;-webkit-print-color-adjust: exact;'
        : 'background-color: #fff;-webkit-print-color-adjust: exact;'

    const stopRow = new HTMLObject(
      'tr',
      backgroundStyle + 'height: 50px; margin-top: 16px;',
      [stopNumber, arrivalTime, name, address]
    )
    stopsBody.insertChild(stopRow)
    rowIndex++
  }

  const table = new HTMLObject('table', 'page-break-before: always;', [
    thead,
    stopsBody,
  ])
  return table
}

const generateTourTable = async (
  tourTableMap,
  index,
  tourNumber,
  pickerIndex: number
) => {
  const title = new HTMLObject(
    'div',
    'font-size: 20px; text-align: left; float: left;',
    `Kommissionierer ${pickerIndex}`
  )
  const counter = new HTMLObject(
    'div',
    'text-align: right;',
    `${index} / ${tourNumber}`
  )
  const titleDiv = new HTMLObject('div', 'page-break-before: always;', [
    title,
    counter,
  ])
  const tour = new HTMLObject(
    'div',
    'font-size: 16px; text-align: left; float: left;',
    `Tour ${tourTableMap.tour.number}`
  )
  const statisticFunctions = await getStatisticsFunctionsBasedOnGlobalSettings()
  const pickerDeadline = await statisticFunctions.getPickerDeadline(
    0,
    0,
    tourTableMap.tour.time
  )
  const time = new HTMLObject(
    'div',
    'text-align: right;',
    getTourTime(pickerDeadline)
  )
  const tourDetails = new HTMLObject('div', 'margin-top: 16px;', [tour, time])
  const table = new HTMLObject(
    'table',
    'padding: 16px; border-collapse: collapse;',
    []
  )
  const htmlRow = new HTMLObject('tr', 'border-bottom: 1px solid black;', [])
  const emptyCell = new HTMLObject(
    'th',
    'width: 135px; font-size: 13px; text-align: left;',
    []
  )
  htmlRow.insertChild(emptyCell)
  for (const headline of tourTableMap.header) {
    let headlineHtml
    if (tourTableMap.header.length > 6) {
      headlineHtml = new HTMLObject(
        'p',
        'width: 120px; height: 135px; word-wrap: break-word; font-size: 10px; text-align: left; margin-right: 8px; -moz-transform: rotate(-90.0deg); -o-transform: rotate(-90.0deg); -webkit-transform: rotate(-90.0deg); filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.083); -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)”; transform: rotate(-90.0deg);',
        `${headline.name}`
      )
    } else {
      headlineHtml = new HTMLObject(
        'p',
        'word-wrap: break-word; font-size: 10px; text-align: left; margin-right: 8px;',
        `${headline.name}`
      )
    }
    const headlineCell = new HTMLObject('th', null, [headlineHtml])
    htmlRow.insertChild(headlineCell)
  }
  const iconHeadline = new HTMLObject(
    'p',
    tourTableMap.header.length > 6
      ? 'width: 120px; height: 135px; word-wrap: break-word; font-size: 10px; text-align: left; margin-right: 8px; -moz-transform: rotate(-90.0deg); -o-transform: rotate(-90.0deg); -webkit-transform: rotate(-90.0deg); filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.083); -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)”; transform: rotate(-90.0deg);'
      : 'word-wrap: break-word; font-size: 10px; text-align: left; margin-right: 8px;',
    `Typ`
  )
  const iconHeadlineCell = new HTMLObject('th', null, [iconHeadline])
  htmlRow.insertChild(iconHeadlineCell)
  table.insertChild(htmlRow)
  let i = 0
  for (const row of tourTableMap.rows) {
    i++
    const rowStyle =
      i % 2 === 0 ? 'background-color: #fff' : 'background-color: #f8f8f8'
    const htmlTourTableRow = new HTMLObject('tr', rowStyle, [])
    const rowTitle = new HTMLObject(
      'td',
      'font-size: 12px; text-align: left; padding-right: 8px;',
      `${row.group.shortName} <br><span style="color: #696969">${row.group.name}</span>`
    )
    htmlTourTableRow.insertChild(rowTitle)
    for (const [cellsIndex, cell] of row.cells.entries()) {
      if (cell !== null) {
        htmlTourTableRow.insertChild(
          new HTMLObject(
            'td',
            'font-size: 11px',
            getWeightString(cell.weight, cell.unit)
          )
        )
      } else {
        htmlTourTableRow.insertChild(
          new HTMLObject('td', 'font-size: 11px', `-`)
        )
      }
      if (cellsIndex === row.cells.length - 1) {
        htmlTourTableRow.insertChild(
          new HTMLObject(
            'td',
            null,
            (cell === null || cell === void 0
              ? void 0
              : cell.groupDelivery.heatingType) === undefined ||
            (cell === null || cell === void 0
              ? void 0
              : cell.groupDelivery.heatingType) === 0
              ? ''
              : [
                  new HTMLObject('img', 'height: 15px; width: 15px', '', {
                    src: getHeatingTypeIconURL(cell.groupDelivery.heatingType),
                  }),
                ],
            undefined
          )
        )
      }
    }
    table.insertChild(htmlTourTableRow)
  }
  return [titleDiv, tourDetails, table]
}

const getHeatingTypeIconURL = heatingType => {
  switch (heatingType) {
    case HeatingType.Passive:
      return passiveUrl
    case HeatingType.Active1of2:
    case HeatingType.Active2of2:
      return activeUrl
    case HeatingType.Thermo:
      return thermoUrl
    default:
      return ''
  }
}

function generateProductionPlaceHeader(
  productionPlace: PdfProductionPlace,
  pageBreak: boolean
) {
  const headerStyle = 'font-size: 16px;margin-top:0.4em;margin-bottom:0.4em;'
  const productionPlaceHeader = new HTMLObject(
    'h2',
    headerStyle,
    `Küche ${productionPlace.name}`
  )
  const productionPlaceEmployess = new HTMLObject(
    'p',
    'font-size: 12px;font-weight:normal;margin-top:0;margin-bottom:0',
    productionPlace.employees.map(e => e.firstName).join(', ')
  )
  const productionPlaceTh = new HTMLObject('th', null, [
    productionPlaceHeader,
    productionPlaceEmployess,
  ])
  const productionPlaceTr = new HTMLObject('tr', null, [productionPlaceTh])
  const productionTableHeader = new HTMLObject('thead', null, [
    productionPlaceTr,
  ])
  return productionTableHeader
}

function getTourTime(time: number): string {
  const hours = Math.floor(time / 3600)
  const seconds = time % 60
  const timeWithoutSeconds = time - seconds
  const minutes = Math.round((timeWithoutSeconds % 3600) / 60)
  return `${hours.toLocaleString('de', {
    minimumIntegerDigits: 2,
  })}:${minutes.toLocaleString('de', {
    minimumIntegerDigits: 2,
  })}`
}

async function generateProductionPlaceInformation(
  productionPlace: PdfProductionPlace
) {
  let pageBreak = false
  const productionPlaceTd = new HTMLObject('td', null, [])
  for (const tour of productionPlace.tours) {
    const productionPlaceTable = new HTMLObject(
      'table',
      'page-break-before:always;',
      []
    )
    const tourHeaderStyle = pageBreak
      ? 'page-break-before:always;font-size: 14px;margin-top:0.4em;margin-bottom:0.4em;'
      : 'font-size: 14px;margin-top:0.4em;margin-bottom:0.4em;'
    pageBreak = true
    const statisticFunctions = await getStatisticsFunctionsBasedOnGlobalSettings()
    const kitchenDeadline = statisticFunctions.getKitchenDeadline(
      tour.time,
      tour.totalNumberOfGroups
    )
    const tourHeader = new HTMLObject(
      'h2',
      tourHeaderStyle,
      `Tour ${tour.number} - ${getTourTime(kitchenDeadline)} Uhr`
    )
    const tourHeaderTh = new HTMLObject('th', null, [tourHeader])
    const tourHeaderTr = new HTMLObject('tr', null, [tourHeaderTh])
    const tourHeaderTHead = new HTMLObject('thead', null, [tourHeaderTr])
    productionPlaceTable.insertChild(tourHeaderTHead)
    for (const [, values] of Object.entries(tour.groups)) {
      const group: Group = values.group
      const groupShortName = new HTMLObject(
        'h3',
        'font-size:12px;font-weight:bold',
        group.shortName
      )
      const groupName = new HTMLObject(
        'span',
        'font-size:12px;margin-left:8px;',
        group.name
      )
      const groupHeader = new HTMLObject(
        'div',
        'display:flex;align-items:center;',
        [groupShortName, groupName]
      )
      const groupMealTable = generateMealTable(values.components)
      const groupDiv = new HTMLObject(
        'div',
        'page-break-inside:avoid;padding-bottom:15px',
        [groupHeader, groupMealTable]
      )
      const groupTd = new HTMLObject('td', null, [groupDiv])
      const groupTr = new HTMLObject('tr', null, [groupTd])
      productionPlaceTable.insertChild(groupTr)
    }
    productionPlaceTd.insertChild(productionPlaceTable)
  }
  const productionPlaceTr = new HTMLObject('tr', null, [productionPlaceTd])
  const prodcutionPlaceInformation = new HTMLObject('tbody', null, [
    productionPlaceTr,
  ])
  return prodcutionPlaceInformation
}

const generateHTMLTable = async (data: PdfProductionDayData) => {
  const table = new HTMLObject('div', 'width: 100%;', [], { id: 'content' })
  let pageBreak = false
  for (const productionPlace of data.productionPlaces) {
    const productionTableHeader = generateProductionPlaceHeader(
      productionPlace,
      pageBreak
    )
    const productionPlaceInformation = await generateProductionPlaceInformation(
      productionPlace
    )
    const productionPlaceDiv = new HTMLObject(
      'table',
      'page-break-before:always !important;',
      [productionTableHeader, productionPlaceInformation],
      { id: 'productionPlaceTable' }
    )
    table.insertChild(productionPlaceDiv)
    pageBreak = true
  }
  return table
}

const generateMealTable = (
  groupDeliverComponents: PdfProductionPlaceDeliveryComponent[]
) => {
  const table = new HTMLObject('table', 'width: 100%;', [])
  // create table headers
  const tableHeader = new HTMLObject(
    'tr',
    'border-bottom: 1px solid black;',
    []
  )
  tableHeader.insertChild(
    new HTMLObject(
      'th',
      'width: 70%; text-align: left; font-size: 11px;',
      'Komponente',
      {
        align: 'left',
      }
    )
  )
  tableHeader.insertChild(
    new HTMLObject('th', 'width: 15%; font-size: 11px;', 'Gewicht', {
      align: 'left',
    })
  )
  tableHeader.insertChild(
    new HTMLObject('th', 'width: 15%; font-size: 11px;', 'Teilkomponente', {
      align: 'left',
    })
  )
  table.insertChild(tableHeader)
  let iter = 0
  for (const groupDeliverComponent of groupDeliverComponents) {
    iter++
    const rowStyle =
      iter % 2 === 0
        ? 'background-color: #fff;-webkit-print-color-adjust: exact;'
        : 'background-color: #f8f8f8;-webkit-print-color-adjust: exact;'
    const row = new HTMLObject('tr', rowStyle, [])
    row.insertChild(
      new HTMLObject('td', 'font-size: 11px;', groupDeliverComponent.meal.name)
    )
    row.insertChild(
      new HTMLObject(
        'td',
        'font-size: 11px;height: 28px;white-space: nowrap;',
        groupDeliverComponent.component.weight +
          ' ' +
          groupDeliverComponent.component.unit
      )
    )
    row.insertChild(
      new HTMLObject(
        'td',
        'font-size: 11px;',
        groupDeliverComponent.meal.isPartial ? 'Ja' : ''
      )
    )
    table.insertChild(row)
  }
  return table
}

export function getProductionPlacesPlanData(
  productionPlaces: ProductionPlace[],
  employeeKitchenAssigment: KitchenEmployeeAssignment[],
  employees: Employee[],
  productionPlaceAssignments: ProductionPlaceAssignments[],
  tours: Tour[],
  mealComponents: MealComponent[],
  groupDeliveryComponents: GroupDeliveryComponent[],
  groupDeliveries: GroupDelivery[],
  groups: Group[]
): PdfProductionPlace[] {
  const data: PdfProductionPlace[] = []
  for (const productionPlace of productionPlaces) {
    let productionPlaceData: any = { ...productionPlace }
    const productionPlaceEmployees = getEmployeesForProductionPlace(
      productionPlace,
      employeeKitchenAssigment,
      employees
    )
    productionPlaceData.employees = productionPlaceEmployees
    // get all mealcomponents for production place
    const assigments = productionPlaceAssignments.filter(
      $0 => $0.productionPlaceRef.path === productionPlace.documentRef.path
    )
    // group assigments to tours
    const tourAssignments = assigments.map($0 => $0.tourRef.path)
    let productionPlaceTours = tours
      .filter($0 => tourAssignments.includes($0.documentRef.path))
      .sort((a, b) => (a.time > b.time ? 1 : -1))
    const productionPlaceToursWithGroups = productionPlaceTours.map(
      productionPlaceTour => {
        const totalNumberOfGroups = groupDeliveries.filter(
          $0 => $0.tourRef.path === productionPlaceTour.documentRef.path
        ).length

        // to get all groups we need all tourassignments
        let currentProductionTour: PdfProductionPlaceTour = {
          ...productionPlaceTour,
          totalNumberOfGroups,
          meal: [],
          groups: {},
        }
        const productionPlaceTourAssigments = assigments.filter(
          $0 => $0.tourRef.path === productionPlaceTour.documentRef.path
        )
        for (const productionPlaceTourAssigment of productionPlaceTourAssigments) {
          const meal = mealComponents.find(
            $0 =>
              $0.documentRef.path ===
              productionPlaceTourAssigment.mealComponentRef.path
          )
          currentProductionTour.meal.push(meal)
        }
        const mealPaths: string[] = currentProductionTour.meal.map(
          $0 => $0.documentRef.path
        )
        const tourGroupsDeliveryComponents = groupDeliveryComponents
          .filter(
            $0 =>
              $0.tourRef.path === productionPlaceTour.documentRef.path &&
              mealPaths.includes($0.mealComponentRef.path)
          )
          .sort((a, b) => (a.sortId > b.sortId ? 1 : -1))
        const tourGroupsDeliveries: TourGroupsDeliveries[] = tourGroupsDeliveryComponents
          .map($0 => ({
            groupDeliveryComponent: $0,
            mealComponent: mealComponents.find(
              $2 => $2.documentRef.path === $0.mealComponentRef.path
            ),
            groupDelivery: groupDeliveries.find(
              $1 => $0.groupDeliveryRef.path === $1.documentRef.path
            ),
          }))
          .map($0 => ({
            ...$0,
            group: groups.find(
              $1 => $0.groupDelivery.groupRef.path === $1.documentRef.path
            ),
          }))
        let productionPlaceTourGroups: PdfProductionGroup = {}
        tourGroupsDeliveries.forEach($0 => {
          const group = $0.group
          if (productionPlaceTourGroups[group.name] == null) {
            productionPlaceTourGroups[group.name] = {
              group,
              components: [
                {
                  component: $0.groupDeliveryComponent,
                  meal: $0.mealComponent,
                },
              ],
            }
          } else {
            productionPlaceTourGroups[group.name].components.push({
              component: $0.groupDeliveryComponent,
              meal: $0.mealComponent,
            })
            productionPlaceTourGroups[group.name].components.sort((a, b) =>
              a.meal.sortId > b.meal.sortId ? 1 : -1
            )
          }
        })
        currentProductionTour.groups = productionPlaceTourGroups
        return currentProductionTour
      }
    )
    productionPlaceData.tours = productionPlaceToursWithGroups
    data.push(productionPlaceData)
  }
  return data
}

export const getTourTableMap = (
  groupDeliverComponents: GroupDeliveryComponent[],
  tours: Tour[],
  meals: MealComponent[],
  groups: Group[],
  groupDeliveries: GroupDelivery[]
): TourTableMap[] => {
  const tourTableObjectInputs: TourTableObjectInput = {}
  for (const tour of tours) {
    tourTableObjectInputs[tour.getLocigalKey()] = {
      tour,
      gdc: [],
      mealComponents: {},
      groups: {},
    }
  }
  for (const gdc of groupDeliverComponents) {
    const groupDeliveryTour = tours.find(
      tour => gdc.tourRef.path === tour.documentRef.path
    )
    const tourTableObjectInput =
      tourTableObjectInputs[groupDeliveryTour.getLocigalKey()]
    tourTableObjectInput.gdc.push(gdc)
    const groupDeliveryMealComponent = meals.find(
      meal => meal.documentRef.path === gdc.mealComponentRef.path
    )
    tourTableObjectInput.mealComponents[
      groupDeliveryMealComponent.getLogicalKey()
    ] = groupDeliveryMealComponent
    const groupDelivery = groupDeliveries.find(
      gd => gd.documentRef.path === gdc.groupDeliveryRef.path
    )
    const groupDeliveryGroup = groups.find(
      group => group.documentRef.path === groupDelivery.groupRef.path
    )
    tourTableObjectInput.groups[
      groupDeliveryGroup.getLogicalKey()
    ] = groupDeliveryGroup
  }
  let tourTableObjects: TourTableMap[] = []
  for (const key in tourTableObjectInputs) {
    const tourTableObjectInput = tourTableObjectInputs[key]
    const tourTableObject: TourTableMap = getTourTableObject(
      tourTableObjectInput,
      meals,
      groups,
      groupDeliveries
    )
    if (tourTableObject.rows.length > 0) {
      tourTableObjects.push(tourTableObject)
    }
  }
  tourTableObjects = tourTableObjects.sort((a, b) =>
    a.tour.number > b.tour.number ? 1 : -1
  )
  return tourTableObjects
}

const getTourTableObject = (
  tourTableObjectInput: TourTableObjectInput,
  meals: MealComponent[],
  groups: Group[],
  groupDeliveries: GroupDelivery[]
): TourTableMap => {
  // Create empty Table first
  const sortedMealComponents = Object.values(
    tourTableObjectInput.mealComponents
  ).sort((c1, c2) => (c1.sortId > c2.sortId ? 1 : -1))
  const cells = sortedMealComponents.map(_ => null)
  const rows = Object.values(tourTableObjectInput.groups).map(group => ({
    group,
    cells: [...cells],
  }))
  // create helper dictionaries to know the get easily the index of a meal component / group
  const mealComponentKeyToIndex = {}
  sortedMealComponents.forEach((mealComponent: MealComponent, index) => {
    mealComponentKeyToIndex[mealComponent.getLogicalKey()] = index
  })
  const groupKeyToIndex = {}
  rows.forEach((row, index) => {
    groupKeyToIndex[row.group.getLogicalKey()] = index
  })
  // fill the TourTableObject rows with Group Delivery Components
  for (const gdc of tourTableObjectInput.gdc) {
    const groupDelivery = groupDeliveries.find(
      gd => gd.documentRef.path === gdc.groupDeliveryRef.path
    )
    const groupDeliveryGroup = groups.find(
      group => group.documentRef.path === groupDelivery.groupRef.path
    )
    const rowIndex = groupKeyToIndex[groupDeliveryGroup.getLogicalKey()]
    const groupDeliveryMealComponent = meals.find(
      meal => meal.documentRef.path === gdc.mealComponentRef.path
    )
    const cellIndex =
      mealComponentKeyToIndex[groupDeliveryMealComponent.getLogicalKey()]
    rows[rowIndex].cells[cellIndex] = { ...gdc, groupDelivery: groupDelivery }
  }
  const tourTableObject: TourTableMap = {
    tour: tourTableObjectInput.tour,
    header: sortedMealComponents,
    rows,
  }
  return tourTableObject
}
