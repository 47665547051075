import React from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

type ClickableCardProps = {
  text: string
  icon: JSX.Element
  onPress: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  blocked?: boolean
}
export const ClickableCard = (props: ClickableCardProps) => {
  return (
    <Card
      className="card"
      style={{ borderRadius: '10%' }}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
    >
      <CardActionArea
        className="cardActionArea"
        onClick={props.onPress}
        disabled={props.blocked}
        style={{ height: 370 }}
      >
        <Box display="flex" justifyContent="center" mt={6}>
          {props.icon}
        </Box>
        <CardContent>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            mt={4}
          >
            <Typography align="center" gutterBottom variant="h5" component="h2">
              {props.text}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
