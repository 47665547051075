import React, { useEffect, useState } from 'react'
import './App.css'
import { PublicDisplay } from './components/organisms/PublicDisplay/PublicDisplay'
import { getMonday, isProduction } from './logic/util'
import { reducer, StateProvider } from './logic/state'
import { auth } from './logic/firebase'
import firebase from 'firebase/app'
import 'firebase/auth'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ControlPanel } from './components/organisms/ControlPanel/ControlPanel'
import { ClickableList } from './components/molecules/ClickableList/ClickableList'
import { EmailList } from './components/organisms/EmailList/EmailList'
import { Sensorpush } from './components/organisms/Sensorpush/Sensorpush'
import { Login } from './components/organisms/Login/Login'
import { CoreTaskList } from './components/organisms/CoreTaskList/CoreTaskList'
import { PrintProductionPlan } from './components/organisms/PrintProductionPlan/PrintProductionPlan'
import { getGlobalSettings } from './services/firestore/global-settings.service'

const ONE_MINUTE_MS = 60000

const initialState = {
  monday: getMonday(new Date()),
}

const App: React.FC = () => {
  const [refreshIntervalCoreTasks, setRefreshIntervalCoreTasks] = useState(
    ONE_MINUTE_MS
  )
  const [error, setError] = useState<Error | null>(null)
  const [user, setUser] = useState<
    firebase.auth.UserCredential | firebase.User | null
  >(null)
  const [isLoading, setIsLoading] = useState(true)

  const logginInParagraph = <p>Du wirst eingeloggt...</p>

  useEffect(() => {
    const retrieveRefreshIntervalCoreTasks = async () => {
      const { refreshIntervalCoreTasks } = await getGlobalSettings()
      setRefreshIntervalCoreTasks(refreshIntervalCoreTasks)
    }
    retrieveRefreshIntervalCoreTasks()
  }, [])

  const login = async () => {
    firebase.auth().onAuthStateChanged(async function(user) {
      try {
        if (user) {
          // User is signed in.
          setUser(user)
          setError(null)
        } else {
          setUser(null)
          // No user is signed in.
        }

        if (user === null) {
          const query = new URLSearchParams(window.location.search)
          let password = query.get('password')
          if (password == null && isProduction() === false) {
            password = 'wuWDjvezDVcTxXirNNYYn7aM'
          }
          if (!password) {
            throw new Error('Kein Passwort in der URL angegeben!')
          }

          const user = await auth.signInWithEmailAndPassword(
            'firebaseauth-backend@kinderkueche.com',
            password
          )
          setUser(user)
          setError(null)
        }
      } catch (error) {
        setUser(null)
        setError(error)
      }
    })
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    login()
  }, [])

  const stillLoggingIn = isLoading || !user
  if (error) {
    return <Login />
  } else if (stillLoggingIn) {
    return logginInParagraph
  } else {
    return (
      <StateProvider initialState={initialState} reducer={reducer}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/home">
              <ClickableList />
            </Route>
            <Route exact path="/">
              <ClickableList />
            </Route>
            <Route path="/control-panel">
              <div className="App">
                <div className="controlPanel">
                  <ControlPanel />
                </div>
              </div>
            </Route>
            <Route path="/public-display">
              <div className="publicDisplay">
                <PublicDisplay />
              </div>
            </Route>
            <Route path="/email-list">
              <div className="emailList">
                <EmailList />
              </div>
            </Route>
            <Route path="/sensorpush">
              <div className="sensorpush">
                <Sensorpush />
              </div>
            </Route>
            <Route path="/login">
              <div className="login">
                <Login />
              </div>
            </Route>
            <Route path="/coreTasks">
              <div className="coreTasks">
                <CoreTaskList
                  refreshIntervalCoreTasks={refreshIntervalCoreTasks}
                />
              </div>
            </Route>
            <Route path="/printProductionPlan/:day">
              <div className="printProductionPlan">
                <PrintProductionPlan />
              </div>
            </Route>
          </Switch>
        </BrowserRouter>
      </StateProvider>
    )
  }
}

export default App
