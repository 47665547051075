import {
  GridCellParams,
  GridRowData,
  GridRowsProp,
} from '@material-ui/data-grid'
import { firestore } from '../../../logic/firebase'
import { CreateDataType } from './EmailTable.types'

export const updateIsSupervisorValue = async (
  params: GridCellParams,
  event:
    | React.MouseEvent<HTMLButtonElement, MouseEvent>
    | React.MouseEvent<Element, MouseEvent>,
  rows: GridRowsProp[],
  setRows: React.Dispatch<React.SetStateAction<(readonly GridRowData[])[]>>,
  createData: CreateDataType
): Promise<void> => {
  event.stopPropagation()
  event.preventDefault()
  const rowId = params.id
  const docString = rows[rowId].ref
  const docRef = firestore.doc(docString)
  setRows(rows => {
    const tempRows = [...rows]
    tempRows[rowId].isSupervisor = !tempRows[rowId].isSupervisor
    return tempRows
  })
  await docRef.update({
    isSupervisor: rows[rowId].isSupervisor,
  })
  getFireStoreData(setRows, createData)
}

export const getFireStoreData = async (
  setRows: React.Dispatch<React.SetStateAction<(readonly GridRowData[])[]>>,
  createData: CreateDataType
) => {
  const data = await firestore.collection('employees').get()
  let rowsTemp = []
  data.docs.forEach((doc, index) => {
    const data = doc.data()
    const roles =
      data.roles === undefined ? '' : data.roles.join(', ').toString()
    const row = createData(
      index,
      data.email,
      data.id,
      data.firstName,
      data.lastName,
      data.isActive === true ? 'Aktiv' : 'Deaktiv',
      data.isSupervisor === true,
      roles,
      doc.ref.path
    )
    rowsTemp.push(row)
  })
  setRows(rowsTemp)
}
