import React from 'react'
import './HeaderPublicDisplay.css'
import {
  AggregatedPunctualityScores,
  PunctualityScoresForDay,
} from '../../../statisticsObject/StatisticObjectInterface'
import { StatisticBloc } from './components/StatisticBloc'
import {
  getViewTypePublicDisplay,
  getWeekNumber,
  twoDigitString,
} from './HeaderPublicDisplayFunctions'
import { TopRowHeader } from './components/TopRowHeader'
import { ClockWithArrows } from './components/ClockWithArrows'
import { DateTime } from 'luxon'

type HeaderPublicDisplayProps = {
  setDay: (date: Date) => void
  date: Date
  punctualityScoresForDay: PunctualityScoresForDay | undefined
  scoresForMonth: AggregatedPunctualityScores
  scoresForWeek: AggregatedPunctualityScores
  showStatistic: boolean
  showEvaluation: boolean
  setShowStatistic: (boolean) => void
}

export enum ViewTypePublicDisplay {
  LiveView,
  SimpleView,
  DetailView,
}

export const HeaderPublicDisplay = (props: HeaderPublicDisplayProps) => {
  let kw = twoDigitString(getWeekNumber(props.date))

  const viewTypePublicDisplay = getViewTypePublicDisplay(
    props.showStatistic,
    props.showEvaluation
  )

  return (
    <div className="header">
      <TopRowHeader
        setDay={props.setDay}
        date={props.date}
        punctualityScoresForDay={props.punctualityScoresForDay}
        scoresForMonth={props.scoresForMonth}
        scoresForWeek={props.scoresForWeek}
        showEvaluation={props.showEvaluation}
        showStatistic={props.showStatistic}
        viewTypePublicDisplay={viewTypePublicDisplay}
        setShowStatistic={props.setShowStatistic}
      />
      {props.showStatistic && (
        <div className="statistics-container">
          <div className="statistic-bloc">
            <StatisticBloc
              title={'Aktueller Tag'}
              loading={false}
              kitchenPercentage={
                props.punctualityScoresForDay === undefined
                  ? '-'
                  : props.punctualityScoresForDay.kitchens
              }
              pickerPercentage={
                props.punctualityScoresForDay === undefined
                  ? '-'
                  : props.punctualityScoresForDay.pickers
              }
              driverPercentage={
                props.punctualityScoresForDay === undefined
                  ? '-'
                  : props.punctualityScoresForDay.drivers
              }
            />
          </div>
          <div className="statistic-divider-container">
            <div className="divider-statistics"></div>
          </div>
          <div className="statistic-bloc">
            <StatisticBloc
              title={`Kalenderwoche ${kw}`}
              loading={props.scoresForWeek.loading}
              kitchenPercentage={props.scoresForWeek.kitchens}
              pickerPercentage={props.scoresForWeek.pickers}
              driverPercentage={props.scoresForWeek.drivers}
            />
          </div>
          <div className="statistic-divider-container">
            <div className="divider-statistics"></div>
          </div>
          <div className="statistic-bloc">
            <StatisticBloc
              title={DateTime.fromJSDate(props.date).toLocaleString({
                month: 'long',
              })}
              loading={props.scoresForMonth.loading}
              kitchenPercentage={props.scoresForMonth.kitchens}
              pickerPercentage={props.scoresForMonth.pickers}
              driverPercentage={props.scoresForMonth.drivers}
            />
          </div>
        </div>
      )}
      {props.showEvaluation && props.showStatistic && (
        <div className="bottom-row">
          <ClockWithArrows
            setDay={props.setDay}
            date={props.date}
            viewTypePublicDisplay={viewTypePublicDisplay}
          />
        </div>
      )}
    </div>
  )
}
