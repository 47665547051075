import React from 'react'
import logo from '../../../../res/kk-logo@3x.png'
import { useHistory } from 'react-router-dom'

type KKLogoProps = {}

export const KKLogo = (props: KKLogoProps) => {
  const historyRoutes = useHistory()

  return (
    <div className="switch">
      {/* KK Logo */}
      <img
        src={logo}
        className="pd-kk-logo"
        alt={logo}
        onClick={() => historyRoutes.push('/')}
      />
    </div>
  )
}
