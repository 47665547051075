import React from 'react'
import './PublicDisplayTourCell.css'
import { StatusElement } from '../../atoms/StatusElement/StatusElement'
import Tooltip from '@material-ui/core/Tooltip'
import { secondsToHHMM } from '../../atoms/Time/Time'
import { tenMinutesInSeconds } from '../../../constants'
import {
  getSecondsSinceMidnight,
  secondsFrom,
} from '../../organisms/PublicDisplay/PublicDisplayFunctions'
import {
  CompletionState,
  KitchenInfo,
  Tour,
} from '../../../statisticsObject/StatisticObjectInterface'

export function isTourInactive(
  kitchenDeadline: number,
  secondsSinceMidnight: number
) {
  return kitchenDeadline - tenMinutesInSeconds > secondsSinceMidnight
}

type PublicDisplayTourCellProps = {
  tour: Tour
  isToday: boolean
  showEvaluation: boolean
}

export const PublicDisplayTourCell = (props: PublicDisplayTourCellProps) => {
  props.tour.kitchenInfos = props.tour.kitchenInfos.sort(compareStrings)

  const tooltipInfo = (
    <div>
      <div>{`Tour time: ${secondsToHHMM(props.tour.tourTime)}`}</div>
      <div>{`groupDeliveriesForTourLength: ${props.tour.groupDeliveriesForTourLength}`}</div>
      <div>{`Kitchen deadline: ${secondsToHHMM(
        props.tour.kitchensSummaryForTour.kitchensDeadline
      )}`}</div>
      <div>{`Picker deadline: ${secondsToHHMM(
        props.tour.pickerInfo.deadline
      )}`}</div>
      <div>{`Driver deadline: ${secondsToHHMM(
        props.tour.driverInfo.deadline
      )}`}</div>
    </div>
  )

  return (
    <Tooltip title={tooltipInfo} arrow>
      <div
        className="container"
        style={{
          opacity: isTourInactive(
            props.tour.kitchensSummaryForTour.kitchensDeadline,
            props.isToday ? getSecondsSinceMidnight() : secondsFrom(23, 30)
          )
            ? 0.2
            : 1,
        }}
      >
        <div className="route-number">{props.tour.tourNumber}</div>
        <div className="vertical-line"></div>
        <div className="kitchenContainer">
          {props.tour.kitchenInfos.map((kitchenInfo, index) => (
            <StatusElement
              key={index}
              text={kitchenInfo.name}
              completionState={calculateCompletionStatus(
                kitchenInfo.completionState,
                kitchenInfo.finished,
                props.showEvaluation
              )}
              deadline={props.tour.kitchensSummaryForTour.kitchensDeadline}
              finished={kitchenInfo.finished}
            />
          ))}
        </div>
        <StatusElement
          key={'cart'}
          text={'cart'}
          completionState={calculateCompletionStatus(
            props.tour.pickerInfo.completionState,
            props.tour.pickerInfo.finished,
            props.showEvaluation
          )}
          deadline={props.tour.pickerInfo.deadline}
          finished={props.tour.pickerInfo.finished}
        />
        <StatusElement
          key={'truck'}
          text={'truck'}
          completionState={calculateCompletionStatus(
            props.tour.driverInfo.completionState,
            props.tour.driverInfo.finished,
            props.showEvaluation
          )}
          deadline={props.tour.driverInfo.deadline}
          finished={props.tour.driverInfo.finished}
        />
      </div>
    </Tooltip>
  )
}

function calculateCompletionStatus(
  completionStateFirebase: CompletionState,
  finished: number,
  showEvaluation: boolean
): CompletionState {
  if (showEvaluation) {
    return completionStateFirebase
  } else {
    return finished > 0 ? CompletionState.InTime : completionStateFirebase
  }
}

export function compareStrings(a: KitchenInfo, b: KitchenInfo) {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}
