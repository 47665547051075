import firebase from 'firebase'
import { KKElement } from './kkElement'
import { COLLECTION_KITCHEN_EMPLOYEEASSIGNMENTS } from '../../../../constants'

export class KitchenEmployeeAssignment extends KKElement {
  deliveryDayRef: firebase.firestore.DocumentReference
  employeeRef: firebase.firestore.DocumentReference
  productionPlaceRef: firebase.firestore.DocumentReference | undefined

  constructor(snap: firebase.firestore.QueryDocumentSnapshot) {
    super(COLLECTION_KITCHEN_EMPLOYEEASSIGNMENTS)
    const data = snap.data()
    this.deliveryDayRef = data.deliveryDayRef
    this.employeeRef = data.employeeRef
    this.productionPlaceRef = data.productionPlaceRef
  }

  getFlatData() {
    return {
      employeeRef: this.employeeRef,
      deliveryDayRef: this.deliveryDayRef,
      productionPlaceRef: this.productionPlaceRef,
      createdAt: new Date(),
      updatedAt: new Date(),
    }
  }
}

export const kitchenEmployeeAssignmentConverter = {
  toFirestore: (kitchenEmployeeAssignment: KitchenEmployeeAssignment) => ({}),
  fromFirestore: (snapshot, options) => {
    return new KitchenEmployeeAssignment(snapshot)
  },
}
