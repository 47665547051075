import { DateTime } from 'luxon'
import { ImageTypes } from '../../atoms/Image/Images'
import { ViewTypePublicDisplay } from '../../molecules/HeaderPublicDisplay/HeaderPublicDisplay'
import { SensorpushBlockProps } from '../../molecules/SensorpushBlock/SensorpushBlock'
import {
  FirebaseGateway,
  FirebaseSensor,
} from '../../../SensorpushFirebaseTypes'
import { SensorpushSensorProps } from '../../molecules/SensorpushSensor/SensorpushSensor'

export interface test {
  data: ViewTypePublicDisplay
}

export function assignSensorDataToGateways(
  gatewayDocs: firebase.default.firestore.QueryDocumentSnapshot<
    FirebaseGateway
  >[],
  sensorDocs: firebase.default.firestore.QueryDocumentSnapshot<FirebaseSensor>[]
): SensorpushBlockProps[] {
  const result: SensorpushBlockProps[] = []
  const gateways = gatewayDocs.map(doc => doc.data())
  const sensors = sensorDocs.map(doc => doc.data())

  for (const gateway of gateways) {
    const filteredSensorsForGateway = sensors.filter(
      sensor => sensor.gatewayId === gateway.id
    )

    const mappedSensorsForResult: SensorpushSensorProps[] = filteredSensorsForGateway.map(
      sensor => {
        const sensorDate = DateTime.fromSeconds(sensor.observed.seconds)
        const now = DateTime.now()
        // red when last update 5 min or older
        const isSensorTimeCurrent: boolean =
          differenceInMinutesBetweenTwoDateTimes(sensorDate, now) < 5
        // if last update is not today show date instead of time of last update
        const isSensorTimeToday: boolean =
          sensorDate.hasSame(now, 'year') &&
          sensorDate.hasSame(now, 'month') &&
          sensorDate.hasSame(now, 'day')
        // time/date of last update only show when icon is red
        const timeStringForSensor = getTimeStringForSensor(
          isSensorTimeCurrent,
          isSensorTimeToday,
          DateTime.fromSeconds(sensor.observed.seconds)
        )
        const mappedSensor: SensorpushSensorProps = {
          header: sensor.name,
          image:
            timeStringForSensor === undefined ? 'greenSensor' : 'redSensor',
          temperatureNumber: sensor.temperature,
          temperatureRangeMin: sensor.temperatureRange.min,
          temperatureRangeMax: sensor.temperatureRange.max,
          temperatureRangeEnabled: sensor.temperatureRange.enabled,
          time: timeStringForSensor,
          // if battery voltage is < 2.3 show red battery icon
          isBatteryEmpty: sensor.battery_voltage < 2.3 ? true : false,
        }
        return mappedSensor
      }
    )
    mappedSensorsForResult.sort(sortSensors)
    // Gateway is red when last update is older then 10 min
    const isGatewayTimeCurrent =
      gateway.last_seen === null
        ? false
        : differenceInMinutesBetweenTwoDateTimes(
            DateTime.fromSeconds(gateway.last_seen.seconds),
            DateTime.now()
          ) >= 10
    const gatewayImageStatus: ImageTypes = isGatewayTimeCurrent
      ? 'redGateway'
      : 'greenGateway'

    result.push({
      gatewayText: gateway.name,
      gatewayImage: gatewayImageStatus,
      sensors: mappedSensorsForResult,
    })
  }
  return sortGreyGatewayToLast(result)
}

export function differenceInMinutesBetweenTwoDateTimes(
  date1: DateTime,
  date2: DateTime
): number {
  return date2.diff(date1, 'minutes').minutes
}

export function sortSensors(
  sensor1: SensorpushSensorProps,
  sensor2: SensorpushSensorProps
) {
  const sensor1Name = sensor1.header.split(' ')[0]
  const sensor2Name = sensor2.header.split(' ')[0]
  let a, b
  sensor1Name.length < 2 ? (a = `0${sensor1Name}`) : (a = `${sensor1Name}`)
  sensor2Name.length < 2 ? (b = `0${sensor2Name}`) : (b = `${sensor2Name}`)
  return a.localeCompare(b)
}

export function getTimeStringForSensor(
  isSensorTimeCurrent: boolean,
  isSensorTimeToday: boolean,
  date: DateTime
) {
  if (isSensorTimeCurrent) {
    return undefined
  } else {
    if (isSensorTimeToday) {
      return date.toFormat('HH:mm') + ' Uhr'
    } else {
      return date.toFormat('d.M.yyyy')
    }
  }
}

export function sortGreyGatewayToLast(
  data: SensorpushBlockProps[]
): SensorpushBlockProps[] {
  const index = data.findIndex(
    elem => elem.gatewayText === 'Sensoren ohne Gateway'
  )
  if (index < 0) {
    return data
  } else {
    return array_move(data, index, data.length - 1)
  }
}

export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr // for testing
}

export function getCelsiusFromFahrenheit(fahrenheit: number): number {
  return Math.round((5 / 9) * (fahrenheit - 32) * 10) / 10
}
