function secondsFrom(hours, minutes) {
  if (hours > 23 || hours < 0)
    throw new Error(`Unvalid input for hours: ${hours}`)
  if (minutes > 59 || minutes < 0)
    throw new Error(`Unvalid input for minutes: ${minutes}`)
  return hours * 3600 + minutes * 60
}

const fifteenMinutesInSeconds = secondsFrom(0, 15)
const fourMinutesInSeconds = secondsFrom(0, 4)
const timeForGroupDelivery = 45

export function getKitchenDeadline(tourTime, groupDeliveriesForTourLength) {
  return (
    tourTime -
    fifteenMinutesInSeconds - // driver time
    (fourMinutesInSeconds + // picker time
      groupDeliveriesForTourLength * timeForGroupDelivery)
  )
}

export function getPickerDeadline(tourTime) {
  return tourTime - fifteenMinutesInSeconds
}
