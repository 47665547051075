import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Button, makeStyles } from '@material-ui/core'

type MultiClickableCardProps = {
  title: string
  buttons: MultiClickableCardButtonProps[]
  icon: JSX.Element
}

type MultiClickableCardButtonProps = {
  text: string
  blocked: boolean
  onPress: () => void
}

export const MultiClickableCard = (props: MultiClickableCardProps) => {
  const classes = useStyles()

  return (
    <Card className="card" style={{ borderRadius: '10%', height: 370 }}>
      <Box
        display="flex"
        justifyContent="center"
        mt={6}
        style={{ marginTop: 15, marginBottom: 5 }}
      >
        {props.icon}
      </Box>
      <CardContent>
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            component="h2"
            style={{ marginTop: -10, marginBottom: 10 }}
          >
            {props.title}
          </Typography>
          {props.buttons.map((buttonProps, index) => {
            return (
              <Button
                key={index}
                onClick={buttonProps.onPress}
                variant="contained"
                className={classes.button}
                disabled={buttonProps.blocked}
                style={{
                  marginTop: index === 0 ? 10 : 0,
                  marginBottom: index === props.buttons.length - 1 ? 0 : 10,
                }}
              >
                {buttonProps.text}
              </Button>
            )
          })}
        </Box>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles({
  button: {
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: 15,
    '&:hover': {
      backgroundColor: 'rgb(148, 187, 87)',
      color: 'white',
    },
  },
})
