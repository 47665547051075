import { ViewTypePublicDisplay } from './HeaderPublicDisplay'

export const getGermanDateString = (date: Date): string => {
  return `${twoDigitString(date.getDate())}.${twoDigitString(
    date.getMonth() + 1
  )}.${date.getFullYear()}`
}

export function getWeekNumber(d: Date): number {
  const newDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
  var dayNum = newDate.getUTCDay() || 7
  newDate.setUTCDate(newDate.getUTCDate() + 4 - dayNum)
  const yearStart = new Date(Date.UTC(newDate.getUTCFullYear(), 0, 1))
  return Math.ceil(
    ((newDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  )
}

export function twoDigitString(num: number) {
  return num.toLocaleString('de-DE', { minimumIntegerDigits: 2 })
}

export function twoDigitStringWith0(string: string) {
  return string.length === 1 ? `0${string}` : string
}

export function getViewTypePublicDisplay(
  showStatistic: boolean,
  showEvaluation: boolean
): ViewTypePublicDisplay {
  if (showStatistic && showEvaluation) {
    return ViewTypePublicDisplay.DetailView
  } else if (!showStatistic && !showEvaluation) {
    return ViewTypePublicDisplay.LiveView
  } else if (!showStatistic && showEvaluation) {
    return ViewTypePublicDisplay.SimpleView
  } else if (showStatistic && !showEvaluation) {
    return ViewTypePublicDisplay.LiveView
  }
}
