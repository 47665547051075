import React, { useState } from 'react'
import './Login.css'
import { Header } from '../../molecules/Header/Header'
import { TextField, Button, makeStyles } from '@material-ui/core'
import { auth } from '../../../logic/firebase'

export const Login = () => {
  const styles = useStyles()
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState<Error | null>(null)

  const emailInputChanged = (event: any) => {
    setEmail(event.target.value)
  }

  const passwordInputChanged = (event: any) => {
    setPassword(event.target.value)
  }

  const login = async () => {
    try {
      await auth.signInWithEmailAndPassword(email, password)
    } catch (error) {
      setError(error)
    }
  }

  return (
    <div>
      <Header />
      <div className="loginContent">
        <h1 className="loginTitle">Login</h1>
        <TextField
          className={styles.loginTextFieldEmail}
          variant="outlined"
          label={'Email'}
          value={email}
          onChange={emailInputChanged}
        />
        <TextField
          className={styles.loginTextFieldPassword}
          variant="outlined"
          label={error !== null ? 'Falsches Passwort' : 'Passwort'}
          value={password}
          onChange={passwordInputChanged}
          error={error !== null}
          type="password"
        />
        <Button
          disabled={email.length === 0 || password.length === 0}
          variant="contained"
          onClick={login}
        >
          Anmelden
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  loginTextFieldEmail: {
    marginTop: 20,
  },
  loginTextFieldPassword: {
    marginTop: 20,
    marginBottom: 30,
  },
}))
