import firebase from 'firebase'
import { COLLECTION_TOURS } from '../../../../constants'
import { KKElement } from './kkElement'

/**
 * Parse the delivery time from the xlsx to return the time in seconds since midnight and the tourId
 * @param deliveryTime the delivery time value from the xlsx cell
 */
export const parseTourTime = (deliveryTime: string | null) => {
  if (deliveryTime === null || deliveryTime === '') {
    throw new Error('Ein Eintrag in der Tour-Spalte ist leer.')
  }
  const re = /T\d\d \d\d:\d\d/
  if (!re.test(deliveryTime)) {
    throw new Error(
      `Das Format für die Tourkennzeichnung bei ${deliveryTime} ist nicht eingehalten!`
    )
  }
  const tourTime = deliveryTime.split(' ')[1]
  const tourId = parseInt(deliveryTime.split(' ')[0].replace('T', ''), 10)
  const [hourStr, minuteStr] = tourTime.split(':')
  const hour = parseInt(hourStr, 10)
  const minute = parseInt(minuteStr, 10)
  const secondsSinceMidnight = hour * 3600 + minute * 60
  return [secondsSinceMidnight, tourId]
}

export class Tour extends KKElement {
  documentRef: firebase.firestore.DocumentReference | undefined
  key: string
  number: number
  time: number
  readyToLeave: number | undefined
  deliveryDayRef: firebase.firestore.DocumentReference | undefined

  constructor(
    tourKey: string,
    tourRef?: firebase.firestore.QueryDocumentSnapshot
  ) {
    super(COLLECTION_TOURS)
    if (tourRef != null) {
      const data = tourRef.data()
      this.key = data.key || data.time
      this.number = data.number
      this.time = data.time
      this.documentRef = tourRef.ref
      this.readyToLeave = data.readyToLeave
      this.deliveryDayRef = data.deliveryDayRef
      return
    }
    const re = /T\d\d \d\d:\d\d/
    if (!re.test(tourKey)) {
      throw new Error(
        `Das Format für die Tourkennzeichnung bei ${tourKey} ist nicht eingehalten!`
      )
    }
    // this.key = excelEntry[0];
    // [this.time, this.number] = parseTourTime(excelEntry[0]);
    this.readyToLeave = -1
    this.key = tourKey
    ;[this.time, this.number] = parseTourTime(tourKey)
  }

  getLocigalKey(): string {
    return this.key.toString()
  }
}

export const tourConverter = {
  toFirestore: (tour: Tour) => ({}),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return new Tour(data.id, snapshot)
  },
}
