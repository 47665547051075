import { COLLECTION_GLOBAL_SETTINGS } from '../../constants'
import { firestore } from '../../logic/firebase'
import { GlobalSettings } from '../../types'
import { StatisticFunctions } from '../statisticsFunctions/statistic-functions'

export const getStatisticsFunctionsBasedOnGlobalSettings = async (): Promise<StatisticFunctions> => {
  try {
    const {
      bonusForGreen,
      bonusForYellow,
      pickerDeadlineLeadTime,
      tourUrgentTimeIntervalDriver,
      pickingTimeStatic,
      pickingTimePerGroup,
      tourUrgentTimeIntervalPicker,
      tourUrgentTimeIntervalKitchen,
    } = await getGlobalSettings()
    const statisticFunctions = new StatisticFunctions(
      bonusForGreen,
      bonusForYellow,
      pickerDeadlineLeadTime,
      tourUrgentTimeIntervalDriver,
      pickingTimeStatic,
      pickingTimePerGroup,
      tourUrgentTimeIntervalPicker,
      tourUrgentTimeIntervalKitchen
    )
    return statisticFunctions
  } catch (error) {
    throw error
  }
}

export const getGlobalSettings = async (): Promise<GlobalSettings> => {
  try {
    const globalSettingsQuerySnapshot = await firestore
      .collection(COLLECTION_GLOBAL_SETTINGS)
      .get()
    if (globalSettingsQuerySnapshot.size < 1)
      throw new Error('No Document found in Collection Global Settings!')
    if (globalSettingsQuerySnapshot.size > 1)
      console.warn(
        'More than one Document found in Collection Global Settings.'
      )
    const globalSettings = globalSettingsQuerySnapshot.docs[0].data() as GlobalSettings
    return globalSettings
  } catch (error) {
    throw error
  }
}
