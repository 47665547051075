import React from 'react'
import { PunctualityScoresForDay } from '../../../../statisticsObject/StatisticObjectInterface'
import { HeaderPercentageIndicators } from '../../../atoms/HeaderPercentageIndicators/HeaderPercentageIndicators'
import './DailyIndicatorsHeader.css'

type DailyIndicatorsHeaderProps = {
  punctualityScoresForDay: PunctualityScoresForDay | undefined
  showStatistic: boolean
  showEvaluation: boolean
}

export const DailyIndicatorsHeader = (props: DailyIndicatorsHeaderProps) => {
  return (
    <div className="right-indicators-container daily-indicators-header">
      <HeaderPercentageIndicators
        iconName={props.showEvaluation ? 'pot.png' : 'pot-black.png'}
        showEvaluation={props.showEvaluation}
        percentage={
          props.punctualityScoresForDay === undefined
            ? '-'
            : props.punctualityScoresForDay.kitchens
        }
      />
      <HeaderPercentageIndicators
        iconName={props.showEvaluation ? 'cart.png' : 'cart-black.png'}
        showEvaluation={props.showEvaluation}
        percentage={
          props.punctualityScoresForDay === undefined
            ? '-'
            : props.punctualityScoresForDay.pickers
        }
      />
      <HeaderPercentageIndicators
        iconName={props.showEvaluation ? 'truck.png' : 'truck-black.png'}
        showEvaluation={props.showEvaluation}
        percentage={
          props.punctualityScoresForDay === undefined
            ? '-'
            : props.punctualityScoresForDay.drivers
        }
      />
    </div>
  )
}
