import Axios, { AxiosResponse } from 'axios'
const moment = require('moment')

export async function listTodaysCoreTasks() {
  const dayStart = moment()
    .startOf('day')
    .format()
  const dayEnd = moment()
    .endOf('day')
    .format()

  const filterParams = {
    GROUP_ID: 42,
    '>=DEADLINE': dayStart,
    '<=DEADLINE': dayEnd,
  }

  //all keys and values in `filterParams` must be encoded, but the '=' and the '&' mustn't be encoded, therefore this loop
  let urlParams = ''
  for (const entry in filterParams) {
    urlParams += `${encodeURIComponent(
      `filter[${entry}]`
    )}=${encodeURIComponent(filterParams[entry])}&`
  }

  const url = `https://hubb.kinderkueche.com/rest/4/j7zmj7sfmffq0pnf/tasks.task.list?${urlParams}`
  console.log(url)
  const response = await Axios.get(url)
  const tasks = response.data.result.tasks

  const promises = []
  for (const task of tasks) {
    promises.push(tagsForTask(task.id))
  }

  const responses = await Promise.all(promises)
  for (let index = 0; index < responses.length; index++) {
    tasks[index].tags = responses[index]
  }
  return tasks
}

async function tagsForTask(taskId) {
  const response = await Axios.get(
    `https://hubb.kinderkueche.com/rest/4/j7zmj7sfmffq0pnf/task.item.gettags?TASK_ID=${taskId}`
  )
  return response.data.result
}
