import React from 'react'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

type DataGridDialogProps = {
  open: boolean
  handleClose: () => void
  title: string
  description: string
  onChangeBitrixIdField: (
    data: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  defaultBitrixIdFieldValue?: number
  onChangeEmailField: (
    data: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  defaultEmailFieldValue?: string
  onCancelDialog: () => void
  onSaveDialog: () => void
  onDeleteDialog?: () => void
  isLoading: boolean
}

export const DataGridDialog = (props: DataGridDialogProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.description}</DialogContentText>
        <TextField
          autoFocus
          onChange={props.onChangeBitrixIdField}
          margin="dense"
          id="bitrixId"
          label="Bitrix ID"
          defaultValue={props.defaultBitrixIdFieldValue}
          fullWidth
          type="number"
        />
        <TextField
          onChange={props.onChangeEmailField}
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          defaultValue={props.defaultEmailFieldValue}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        {props.onDeleteDialog && (
          <>
            <Button onClick={props.onDeleteDialog} color="secondary">
              Eintrag Löschen
            </Button>
            <div style={{ flex: '1 0 0' }} />
          </>
        )}
        <Button
          onClick={props.onCancelDialog}
          style={{ color: 'darkSlateGrey' }}
        >
          Abbrechen
        </Button>
        <Button onClick={props.onSaveDialog} color="primary">
          Speichern
        </Button>
      </DialogActions>
      {props.isLoading && <LinearProgress />}
    </Dialog>
  )
}
