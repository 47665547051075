import { Box, Typography } from '@material-ui/core'
import React from 'react'

type LiveViewButtonProps = {}

export const LiveViewButton = (props: LiveViewButtonProps) => {
  return (
    <Box display={'flex'} justifyContent={'flex-end'}>
      <Box className="live-view-indicator">
        <Typography variant="h6" style={{ color: 'black', fontSize: 18 }}>
          LIVE ANSICHT
        </Typography>
      </Box>
    </Box>
  )
}
