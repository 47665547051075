import { Tooltip } from '@material-ui/core'
import React from 'react'
import { CompletionState } from '../../../statisticsObject/StatisticObjectInterface'
import { secondsToHHMM } from '../Time/Time'
import './StatusElement.css'

const cartIcon: string = require('./../../../assets/cart.png')
const truckIcon: string = require('./../../../assets/truck.png')
const cartBlackIcon: string = require('./../../../assets/cart-black.png')
const truckBlackIcon: string = require('./../../../assets/truck-black.png')

type StatusElementProps = {
  text: string
  completionState: CompletionState
  deadline: number
  finished: number
}
export const StatusElement = (props: StatusElementProps) => {
  let icon: string
  let classes: any
  var classNames = require('classnames')

  if (props.text === 'cart') icon = cartIcon
  if (props.text === 'truck') icon = truckIcon
  switch (props.completionState) {
    case CompletionState.WaitingForPreviousStep:
      if (props.text === 'cart') icon = cartBlackIcon
      if (props.text === 'truck') icon = truckBlackIcon
      classes = classNames({
        'box-container': true,
        'box-container-black': true,
      })
      break
    case CompletionState.InTime:
      classes = classNames({
        'box-container': true,
        'box-container-green': true,
      })
      break
    case CompletionState.JustInTime:
      classes = classNames({
        'box-container': true,
        'box-container-yellow': true,
      })
      break
    case CompletionState.OverDue:
      classes = classNames({
        'box-container': true,
        'box-container-red': true,
      })
      break
    default:
      classes = classNames({
        'box-container': true,
        'box-container-inactive': true,
      })
      break
  }

  const tooltipInfo = (
    <div>
      <div>{`Time ready: ${secondsToHHMM(props.finished)}`}</div>
      <div>{`Deadline: ${secondsToHHMM(props.deadline)}`}</div>
    </div>
  )

  if (props.text.length === 1) {
    return (
      <Tooltip title={tooltipInfo} arrow placement={'top'}>
        <div className={classes}>
          {props.completionState === CompletionState.Inactive ? '' : props.text}
        </div>
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title={tooltipInfo} arrow placement={'top'}>
        <div className={classes}>
          <img src={icon} alt={props.text} height={29} />
        </div>
      </Tooltip>
    )
  }
}
