import React from 'react'
import { BoldTextOrDate } from '../../../atoms/Clock/BoldTextOrDate'
import {
  AggregatedPunctualityScores,
  PunctualityScoresForDay,
} from '../../../../statisticsObject/StatisticObjectInterface'
import { KKLogo } from './KKLogo'
import { ClockWithArrows } from './ClockWithArrows'
import { LiveViewButton } from './LiveViewButton'
import { DailyIndicatorsHeader } from './DailyIndicatorsHeader'
import { ViewTypePublicDisplay } from '../HeaderPublicDisplay'

type TopRowHeaderProps = {
  setDay: (date: Date) => void
  date: Date
  punctualityScoresForDay: PunctualityScoresForDay | undefined
  scoresForMonth: AggregatedPunctualityScores
  scoresForWeek: AggregatedPunctualityScores
  showStatistic: boolean
  showEvaluation: boolean
  viewTypePublicDisplay: ViewTypePublicDisplay
  setShowStatistic: (boolean) => void
}

export const TopRowHeader = (props: TopRowHeaderProps) => {
  return (
    <div className="top-row">
      <KKLogo />
      {/* Show "Detaillierte Auswertung" Title for detailed view */}
      {props.viewTypePublicDisplay === ViewTypePublicDisplay.DetailView && (
        <div className="switch">
          <BoldTextOrDate
            text={'Detaillierte Auswertung'}
            date={props.date}
            outerStyle={{ marginLeft: 30, marginRight: 30 }}
            innerStyle={{ width: 300 }}
            showClockOnLiveView={false}
          />
        </div>
      )}
      {/* Show Date with arrows in header when in simple view */}
      {props.viewTypePublicDisplay === ViewTypePublicDisplay.SimpleView && (
        <ClockWithArrows
          setDay={props.setDay}
          date={props.date}
          viewTypePublicDisplay={props.viewTypePublicDisplay}
        />
      )}
      {/* Show Clock with arrows in header when in live view */}
      {props.viewTypePublicDisplay === ViewTypePublicDisplay.LiveView && (
        <ClockWithArrows
          setDay={props.setDay}
          date={props.date}
          viewTypePublicDisplay={props.viewTypePublicDisplay}
        />
      )}
      {/* Show "Live Ansicht" for live view on right side */}
      {props.viewTypePublicDisplay === ViewTypePublicDisplay.LiveView && (
        <LiveViewButton />
      )}
      {/* Show daily indicators for simple view */}
      {props.viewTypePublicDisplay === ViewTypePublicDisplay.SimpleView && (
        <DailyIndicatorsHeader
          punctualityScoresForDay={props.punctualityScoresForDay}
          showEvaluation={props.showEvaluation}
          showStatistic={props.showStatistic}
        />
      )}
    </div>
  )
}
