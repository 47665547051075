import firebase from 'firebase'
import { KKElement } from './kkElement'
import { COLLECTION_GROUP_DELIVIERY_COMPONENTS } from '../../../../constants'

export class GroupDeliveryComponent extends KKElement {
  documentRef: firebase.firestore.DocumentReference | undefined
  tourRef: firebase.firestore.DocumentReference
  weight: number
  unit: string
  mealComponentRef: firebase.firestore.DocumentReference
  groupDeliveryRef: firebase.firestore.DocumentReference
  isCooking: number
  sortId: number

  constructor(snap: firebase.firestore.QueryDocumentSnapshot) {
    super(COLLECTION_GROUP_DELIVIERY_COMPONENTS)
    const data = snap.data()
    this.tourRef = data.tourRef
    this.mealComponentRef = data.mealComponentRef
    this.groupDeliveryRef = data.groupDeliveryRef
    this.weight = data.weight
    this.unit = data.unit
    this.isCooking = data.isCooking
    this.sortId = data.sortId
    this.documentRef = snap.ref
  }

  getFlatData(
    db: firebase.firestore.Firestore,
    dayRef: firebase.firestore.DocumentReference
  ) {
    const flat = {
      weight: this.weight,
      unit: this.unit,
      mealComponentRef: this.mealComponentRef,
      groupDeliveryRef: this.groupDeliveryRef,
      tourRef: this.tourRef,
      potCount: 0,
      cookingCompleted: -1,
      isCooking: -1,
      sortId: this.sortId,
      deliveryDayRef: dayRef,
      createdAt: new Date(),
      updatedAt: new Date(),
    }
    return flat
  }
}

export const groupDeliveryComponentConverter = {
  toFirestore: (groupDelivery: GroupDeliveryComponent) => ({}),
  fromFirestore: (snapshot, options) => {
    return new GroupDeliveryComponent(snapshot)
  },
}
