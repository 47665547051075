import { firestore } from '../../../logic/firebase'
import {
  COLLECTION_GROUP_DELIVIERIES,
  COLLECTION_GROUP_DELIVIERY_COMPONENTS,
  COLLECTION_KITCHEN_EMPLOYEEASSIGNMENTS,
  COLLECTION_MEAL_COMPONENTS,
  COLLECTION_PRODUCTION_PLACE_ASSIGNMENTS,
  COLLECTION_TOURS,
} from '../../../constants'
import { DELIVERY_DAY } from '../../../logic/util'
import { tourConverter } from './model/tour'
import { groupDeliveryConverter, GroupDelivery } from './model/groupDelivery'
import { groupConverter } from './model/group'
import { groupDeliveryComponentConverter } from './model/groupDeliveryComponent'
import {
  ProductionPlaceAssignments,
  productionPlaceAssignmentsConverter,
} from './model/productionPlaceAssignments'
import { mealComponentConverter } from './model/mealComponent'
import {
  KitchenEmployeeAssignment,
  kitchenEmployeeAssignmentConverter,
} from './model/employeeKitchenAssignment'
import { Employee, employeeConverter } from './model/employee'
import {
  ProductionPlace,
  productionPlaceConverter,
} from './model/productionPlaces'

export async function getAllTours(deliveryDay: string) {
  const deliveryDayRef = firestore.collection(DELIVERY_DAY).doc(deliveryDay)
  const queryGroupDeliveries = await firestore
    .collection(COLLECTION_TOURS)
    .where('deliveryDayRef', '==', deliveryDayRef)
    .withConverter(tourConverter)
    .get()
  return queryGroupDeliveries.docs.map(value => value.data())
}

export async function getAllGroupDeliveries(deliveryDay: string) {
  const deliveryDayRef = firestore.collection(DELIVERY_DAY).doc(deliveryDay)
  const queryGroupDeliveries = await firestore
    .collection(COLLECTION_GROUP_DELIVIERIES)
    .where('deliveryDayRef', '==', deliveryDayRef)
    .withConverter(groupDeliveryConverter)
    .get()
  return queryGroupDeliveries.docs.map(value => value.data())
}

export async function getAllGroups(groupDelivery: GroupDelivery[]) {
  const groupReferencesArr = groupDelivery.map(
    delivery => delivery.groupRef.path
  )
  const groupReferenceSet = Array.from(new Set(groupReferencesArr))
  const groupsSnapshots = await Promise.all(
    groupReferenceSet.map(groupRef =>
      firestore
        .doc(groupRef)
        .withConverter(groupConverter)
        .get()
    )
  )
  return groupsSnapshots.map(snap => snap.data())
}

export async function getAllGroupDeliveryComponent(deliveryDay: string) {
  const deliveryDayRef = firestore.collection(DELIVERY_DAY).doc(deliveryDay)
  const queryGroupDeliveries = await firestore
    .collection(COLLECTION_GROUP_DELIVIERY_COMPONENTS)
    .where('deliveryDayRef', '==', deliveryDayRef)
    .withConverter(groupDeliveryComponentConverter)
    .get()
  return queryGroupDeliveries.docs.map(value => value.data())
}

export async function getAllProductionsPlaceAssignments(deliveryDay: string) {
  const deliveryDayRef = firestore.collection(DELIVERY_DAY).doc(deliveryDay)
  const queryProductionPlaceAssignments = await firestore
    .collection(COLLECTION_PRODUCTION_PLACE_ASSIGNMENTS)
    .where('deliveryDayRef', '==', deliveryDayRef)
    .withConverter(productionPlaceAssignmentsConverter)
    .get()
  return queryProductionPlaceAssignments.docs.map(value => value.data())
}

export async function getAllMealComponents(deliveryDay: string) {
  const deliveryDayRef = firestore.collection(DELIVERY_DAY).doc(deliveryDay)
  const queryMealComponents = await firestore
    .collection(COLLECTION_MEAL_COMPONENTS)
    .where('deliveryDayRef', '==', deliveryDayRef)
    .withConverter(mealComponentConverter)
    .get()
  return queryMealComponents.docs.map(value => value.data())
}

export async function getAllKitchenEmployeeAssignments(deliveryDay: string) {
  const deliveryDayRef = firestore.collection(DELIVERY_DAY).doc(deliveryDay)
  const employeeKitchenAssignments = await firestore
    .collection(COLLECTION_KITCHEN_EMPLOYEEASSIGNMENTS)
    .where('deliveryDayRef', '==', deliveryDayRef)
    .withConverter(kitchenEmployeeAssignmentConverter)
    .get()
  return employeeKitchenAssignments.docs.map(value => value.data())
}

export async function getAllEmployees(
  kitchenEmployeeAssignments: KitchenEmployeeAssignment[]
) {
  const employeeReferencesArr = kitchenEmployeeAssignments.map(
    assignment => assignment.employeeRef.path
  )
  const employeeReferencesSet = Array.from(new Set(employeeReferencesArr))
  const employeeSnapshots = await Promise.all(
    employeeReferencesSet.map(employeeRef =>
      firestore
        .doc(employeeRef)
        .withConverter(employeeConverter)
        .get()
    )
  )
  return employeeSnapshots.map(snap => snap.data())
}

export async function getAllProductionPlaces(
  productionPlaceAssignments: ProductionPlaceAssignments[]
) {
  const productionPlaceArr = productionPlaceAssignments.map(
    assignment => assignment.productionPlaceRef.path
  )
  const productionPlaceSet = Array.from(new Set(productionPlaceArr))
  const productionPlaceSnapshots = await Promise.all(
    productionPlaceSet.map(productionPlaceRef =>
      firestore
        .doc(productionPlaceRef)
        .withConverter(productionPlaceConverter)
        .get()
    )
  )
  return productionPlaceSnapshots.map(snap => snap.data())
}

export function getEmployeesForProductionPlace(
  productionPlace: ProductionPlace,
  employeeKitchenAssigment: KitchenEmployeeAssignment[],
  employees: Employee[]
) {
  // get employees for production place
  const productionPlaceRef = productionPlace.documentRef
  // get all employees from employeeAssignment
  const employeeRefs = employeeKitchenAssigment
    .filter(
      assigment =>
        assigment.productionPlaceRef &&
        assigment.productionPlaceRef.path === productionPlaceRef.path
    )
    .map($0 => $0.employeeRef.path)
  const productionPlaceEmployees = employees.filter(employee =>
    employeeRefs.includes(employee.documentRef.path)
  )
  return productionPlaceEmployees
}
