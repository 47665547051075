import * as bitrix from './logic/bitrix'
import { getKitchenDeadline, getPickerDeadline } from './logic/utils'
// import * as types from './types'

const kkShared = {
  bitrix: bitrix,
  //   types: types
  utils: { getKitchenDeadline, getPickerDeadline },
}

export default kkShared
