import React, { useState } from 'react'
import './FileComponent.css'
import success from '../../../res/timestamp.svg'
import error from '../../../res/error.svg'
import loading from '../../../res/loading-spinner.svg'
import excelFile from '../../../res/excel-file.svg'
import { DeliveryDayLoadingState } from '../../../types'
import Dialog from '@material-ui/core/Dialog'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
type FileComponentProps = {
  deliveryDayLoadingState: DeliveryDayLoadingState
}

export const FileComponent: React.FC<FileComponentProps> = props => {
  // let deliveryDay: DeliveryDay = {
  //   date: "2020-01-15",
  //   createdAt: firebase.firestore.Timestamp.now(),
  //   uploadProgress: 100,
  //   productionPlanImportLog: "blub",
  //   productionPlanImportSuccessful: true,
  //   filename: "example.xlsx"
  // };
  // let deliveryDayLoadingState: DeliveryDayLoadingState = {
  //   state: "loaded",
  //   // @ts-ignore
  //   deliveryDay
  // };
  let deliveryDayLoadingState = props.deliveryDayLoadingState
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false)

  const showFullError = () => {
    setErrorDialogOpen(true)
  }

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false)
  }

  const renderSingleLine = (text: string, image: any = null) => {
    return (
      <div className="container-centered">
        <div className="centered-text">
          {image && (
            <img
              style={{
                verticalAlign: 'middle',
                paddingRight: 16,
                paddingBottom: 2,
              }}
              src={image}
              alt=""
            />
          )}
          {text}
        </div>
      </div>
    )
  }

  const renderDateOrErrorLine = (dateOrError: string) => {
    if (dateOrError.length <= 85) {
      return dateOrError
    }
    return (
      <div>
        <span>{`${dateOrError.slice(0, 61)}... `}</span>
        <a href="#" onClick={showFullError}>
          {'mehr sehen'}
        </a>
      </div>
    )
  }

  const renderTwoLines = (
    filename: string,
    dateOrError: string,
    image: any
  ) => {
    return (
      <div className="container-date-element">
        <div className="file-name">{filename}</div>
        <div className="status-bar">
          <img src={image} alt="" />
          <div className="status-bar-text">
            {renderDateOrErrorLine(dateOrError)}
          </div>
        </div>
        <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
          <DialogTitle>{'Fehler beim Import der Excel-Datei'}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dateOrError}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseErrorDialog} color="primary">
              Fertig
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  if (deliveryDayLoadingState.state === 'loading') {
    return renderSingleLine('Lade ...', loading)
  } else if (deliveryDayLoadingState.state === 'loaded') {
    const deliveryDay = deliveryDayLoadingState.deliveryDay
    if (deliveryDay.uploadProgress === 0) {
      return renderSingleLine('Produktionsplan wird hochgeladen', loading)
    } else if (deliveryDay.productionPlanImportSuccessful === false) {
      return renderTwoLines(
        deliveryDay.filename,
        deliveryDay.productionPlanImportLog,
        error
      )
    } else if (deliveryDay.warning != null) {
      return renderTwoLines(
        'Import Successful. PDF could not be generated: ' + deliveryDay.warning,
        deliveryDay.createdAt.toDate().toLocaleString(),
        success
      )
    } else {
      return renderTwoLines(
        deliveryDay.filename,
        deliveryDay.createdAt.toDate().toLocaleString(),
        success
      )
    }
  } else if (deliveryDayLoadingState.state === 'error') {
    return renderSingleLine('Fehler beim Abfragen der Produktionsdaten', error)
  } else if (deliveryDayLoadingState.state === 'deleting') {
    return renderSingleLine('Lösche Produktionsplan ...', loading)
  } else {
    return renderSingleLine('Produktionsplan hier ablegen', excelFile)
  }
}
