import React, { useState } from 'react'
import './ClickableList.css'
import { Header } from '../Header/Header'
import ControlCameraRoundedIcon from '@material-ui/icons/ControlCameraRounded'
import VideoLabelRoundedIcon from '@material-ui/icons/VideoLabelRounded'
import GroupIcon from '@material-ui/icons/GroupRounded'
import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded'
import GridRoundedIcon from '@material-ui/icons/GridOnRounded'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import { ClickableCard } from '../../atoms/ClickableCard/clickable-card'
import { MultiClickableCard } from '../../atoms/MultiClickableCard/multi-clickable-card'
import sensorPushLogoBlack from '../../../assets/sensorPushLogo-black.png'
import sensorPushLogoWhite from '../../../assets/sensorPushLogo-white.png'
import { cloudFunctions } from '../../../logic/firebase'
import { CLOUD_FUNCTION_CREATE_ANALYSIS_CSV } from '../../../constants'
import { exec } from 'child_process'

type ClickableListProps = {}

const sensorPushLogoWhiteJSXElement = (
  <img
    src={sensorPushLogoWhite}
    alt="sensorPush-logo-icon"
    style={{ height: 80, paddingBottom: 20 }}
  />
)

const sensorPushLogoBlackJSXElement = (
  <img
    src={sensorPushLogoBlack}
    alt="sensorPush-logo-icon"
    style={{ height: 80, paddingBottom: 20 }}
  />
)

async function createExport() {
  const callable = cloudFunctions.httpsCallable(
    CLOUD_FUNCTION_CREATE_ANALYSIS_CSV
  )
  const csvContent = await callable()

  const element = document.createElement('a')
  const file = new Blob([csvContent.data.csvContent], {
    type: 'text/plain',
  })
  element.href = URL.createObjectURL(file)
  element.download = 'Auswertung.csv'
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

export const ClickableList = (props: ClickableListProps) => {
  const history = useHistory()

  const [creatingCSVExport, setCreatingCSVExport] = useState(false)
  const [sensorPushIcon, setSensorPushIcon] = useState(
    sensorPushLogoBlackJSXElement
  )
  return (
    <div>
      <Header showLogoutButton={true} />
      <div className="clickableListContent">
        <Typography className="buttonBlockHeader" align="center" variant="h5">
          {'Administration'}
        </Typography>
        <div className="buttonBlock">
          <ClickableCard
            text="Produktionsplanung"
            icon={<ControlCameraRoundedIcon style={{ fontSize: 100 }} />}
            onPress={() => history.push('/control-panel')}
          />
          <ClickableCard
            text="Mitarbeiterliste"
            icon={<GroupIcon style={{ fontSize: 100 }} />}
            onPress={() => history.push('/email-list')}
          />
        </div>
        <Typography className="buttonBlockHeader" align="center" variant="h5">
          {'Auswertungen'}
        </Typography>
        <div className="buttonBlock">
          <MultiClickableCard
            title="Public Display"
            buttons={[
              {
                text: 'Live Ansicht',
                blocked: false,
                onPress: () => history.push('/public-display'),
              },
              {
                text: 'Simple Auswertung',
                blocked: false,
                onPress: () =>
                  history.push('/public-display?showEvaluation=true'),
              },
              {
                text: 'Detaillierte Auswertung',
                blocked: false,
                onPress: () =>
                  history.push(
                    '/public-display?showEvaluation=true&extendedHeader=true'
                  ),
              },
            ]}
            icon={<VideoLabelRoundedIcon style={{ fontSize: 100 }} />}
          />
          <ClickableCard
            text="Sensor Push"
            icon={sensorPushIcon}
            onMouseOver={() => setSensorPushIcon(sensorPushLogoWhiteJSXElement)}
            onMouseOut={() => setSensorPushIcon(sensorPushLogoBlackJSXElement)}
            onPress={() => history.push('/sensorpush')}
          />
          <ClickableCard
            text="Kernaufgaben"
            icon={<PlaylistAddCheckRoundedIcon style={{ fontSize: 100 }} />}
            onPress={() => history.push('/coreTasks')}
          />
          <ClickableCard
            text={creatingCSVExport ? 'wird erstellt...' : 'CSV Export'}
            blocked={creatingCSVExport}
            icon={<GridRoundedIcon style={{ fontSize: 100 }} />}
            onPress={async () => {
              setCreatingCSVExport(true)
              await createExport()
              setCreatingCSVExport(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}
