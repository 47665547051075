import firebase from 'firebase'

export class KKElement {
  documentRef: firebase.firestore.DocumentReference | undefined
  collection: string
  constructor(collection: string) {
    this.collection = collection
  }
  getLocigalKey(): string {
    return ''
  }
  getFlatData(
    db: firebase.firestore.Firestore,
    dayRef: firebase.firestore.DocumentReference
  ) {
    return {}
  }
}
