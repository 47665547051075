import React from 'react'
import './Header.css'
import logo from '../../../res/kk-logo@3x.png'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { auth } from '../../../logic/firebase'

type HeaderProps = {
  showLogoutButton?: boolean
}

export const Header = (props: HeaderProps) => {
  const { showLogoutButton = false } = props
  const history = useHistory()
  return (
    <div className="header-middle">
      <div className="header-left-area"></div>
      <div className="header-center-area">
        <img
          src={logo}
          className="kk-logo"
          alt={logo}
          onClick={() => history.push('/')}
        />
      </div>

      {/* <div style={{ flex: 1 }}></div> */}
      {showLogoutButton && (
        <div className="header-right-area">
          <div className="logoutButton">
            <Button variant="contained" onClick={() => auth.signOut()}>
              Ausloggen
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
