import firebase from 'firebase'
import { KKElement } from './kkElement'
import { COLLECTION_GROUP_DELIVIERIES } from '../../../../constants'
import { HeatingType } from './heatinType'

export class GroupDelivery extends KKElement {
  documentRef: firebase.firestore.DocumentReference | undefined
  groupRef: firebase.firestore.DocumentReference
  tourRef: firebase.firestore.DocumentReference
  deliveryDayRef: firebase.firestore.DocumentReference
  sortId: number
  pickingCompleted: number
  heatingType: HeatingType

  constructor(snapshot: firebase.firestore.QueryDocumentSnapshot) {
    super(COLLECTION_GROUP_DELIVIERIES)
    const data = snapshot.data()
    this.groupRef = data.groupRef
    this.tourRef = data.tourRef
    this.deliveryDayRef = data.deliveryDayRef
    this.pickingCompleted = data.pickingCompleted
    this.sortId = data.sortId
    this.heatingType = data.heatingType
    this.documentRef = snapshot.ref
  }

  getFlatData() {
    const flat = {
      tourRef: this.documentRef,
      groupRef: this.documentRef,
      deliveryDayRef: this.deliveryDayRef,
      pickingCompleted: -1,
      sortId: this.sortId,
      createdAt: new Date(),
      updatedAt: new Date(),
    }
    // only add heatingType if not undefined
    if (this.heatingType !== undefined) {
      // @ts-ignore
      flat['heatingType'] = this.heatingType
    }
    return flat
  }
}

export const groupDeliveryConverter = {
  toFirestore: (groupDelivery: GroupDelivery) => ({}),
  fromFirestore: (snapshot, options) => {
    return new GroupDelivery(snapshot)
  },
}
