import React, { useEffect, useState } from 'react'
import { DateElement } from '../../atoms/DateElement/DateElement'
import { DragAndDrop } from '../../functionality/DragAndDrop/DragAndDrop'
import { FileComponent } from '../FileComponent/FileComponent'
import './DeliveryDay.css'
import { formatDateForCloudFunction } from '../../../logic/util'
import { firestore, cloudFunctions } from '../../../logic/firebase'
import { DeliveryDay, DeliveryDayLoadingState } from '../../../types'
import { ButtonGroup } from '../ButtonGroup/ButtonGroup'

type DeliveryDayCellProps = {
  date: Date
}

const importFile = cloudFunctions.httpsCallable('importFileCall')

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export const DeliveryDayCell = (props: DeliveryDayCellProps) => {
  const [data, setData] = useState<DeliveryDayLoadingState>({
    state: 'loading',
  })
  const [deleting, setDeleting] = useState<boolean>(false)
  const [importingEmployees, setImportingEmployees] = useState<boolean>(false)
  const [employeesImported, setEmployeesImported] = useState<boolean>(false)

  const handleDrop = async (files: FileList) => {
    // send data to cloud function.
    if (uploading || deleting) {
      return
    }
    try {
      const formData = new FormData()
      formData.append('file', files[0])
      formData.append('date', formatDateForCloudFunction(props.date))
      const base64File = await toBase64(files[0])
      await importFile({
        file: base64File,
        date: formatDateForCloudFunction(props.date),
        filename: files[0].name,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const deleteButtonPressed = async () => {
    try {
      const date = formatDateForCloudFunction(props.date)
      const deleteDeliveryDay = cloudFunctions.httpsCallable(
        'deleteDeliveryDay'
      )
      setDeleting(true)
      setData({ state: 'deleting' })
      await deleteDeliveryDay({ date: date })
      setData({ state: 'not_available' })
      setDeleting(false)
    } catch (e) {
      console.error(e)
    }
  }

  const onDownloadButtonPressed = async (url: string) => {
    window.open(url)
  }

  const onImportEmployeesButtonPressed = async () => {
    const date = formatDateForCloudFunction(props.date)
    const importEmployeesForDeliveryDay = cloudFunctions.httpsCallable(
      'importEmployeesForDeliveryDay'
    )
    setImportingEmployees(true)
    await importEmployeesForDeliveryDay({ date: date })
    setImportingEmployees(false)
  }

  useEffect(() => {
    setData({ state: 'loading' })
    const id = formatDateForCloudFunction(props.date)
    const deliveryDayRef = firestore.doc(`deliveryDays/${id}`)
    return deliveryDayRef.onSnapshot(snapshotData => {
      if (snapshotData.exists) {
        const deliveryDayData = snapshotData.data()
        let deliveryDay: DeliveryDay | undefined
        //Fix: Sometimes a deliveryDay comes as plain object, sometimes it comes as an wrapped object in {deliveryDay: ...}
        if (deliveryDayData != null && deliveryDayData.createdAt != null) {
          deliveryDay = deliveryDayData as DeliveryDay
        } else if (
          deliveryDayData != null &&
          deliveryDayData.deliveryDay != null &&
          deliveryDayData.deliveryDay.createdAt != null
        ) {
          deliveryDay = deliveryDayData.deliveryDay
        }

        if (deliveryDay != null) {
          setData({ state: 'loaded', deliveryDay: deliveryDay })

          firestore
            .collection('kitchenEmployeeAssignments')
            .where('deliveryDayRef', '==', deliveryDayRef)
            .onSnapshot(snapshot => {
              setEmployeesImported(snapshot.size > 0)
            })
        } else {
          setData({ state: 'not_available' })
        }
      } else {
        setData({ state: 'not_available' })
      }
    })
  }, [props.date])

  let uploading = false
  if (data.state === 'loaded') {
    if (data.deliveryDay.uploadState === 'uploading') {
      uploading = true
    }
  }

  return (
    <DragAndDrop
      handleDrop={handleDrop}
      uploading={uploading}
      deleting={deleting}
    >
      <div className="container-delivery-day">
        <DateElement date={props.date} />
        <div className="divider" />
        <FileComponent deliveryDayLoadingState={data} />
        {data.state === 'loaded' && (
          <ButtonGroup
            deliveryDay={data.deliveryDay}
            deleting={deleting}
            uploading={uploading}
            onDownloadButtonPressed={onDownloadButtonPressed}
            importingEmployees={importingEmployees}
            importEmployeesButtonPressed={onImportEmployeesButtonPressed}
            deleteButtonPressed={deleteButtonPressed}
            state={data.state}
            employeesImported={employeesImported}
          ></ButtonGroup>
        )}
      </div>
    </DragAndDrop>
  )
}
